import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PROJECTS } from 'constants/projects';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { DesktopMobileGrid } from 'components/DesktopMobileGrid';

const SLeftImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const SRightImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 110%;

   @media (max-width: 768px), (orientation: portrait) {
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
`;

export const NewBalanceView = () => {
    const images = useStaticQuery(graphql`
        query NewBalanceImages {
            main: file(name: { eq: "new-balance-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            mobile: file(name: { eq: "new-balance-desktop" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            desktop: file(name: { eq: "new-balance-presentation" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }
    `);

    const { mobile, desktop, main } = images;

    return (
        <ProjectMainLayout projectName={PROJECTS.newBalance.name}>
            <DesktopMobileGrid
                main={main}
                changeOrder
                bgColor="new_balance_bg"
                leftImage={
                    <SLeftImageWrapper>
                        <GatsbyImage image={getImage(desktop)} alt={PROJECTS.newBalance.name} />
                    </SLeftImageWrapper>
                }
                rightImage={
                    <SRightImageWrapper>
                        <GatsbyImage image={getImage(mobile)} alt={PROJECTS.newBalance.name} />
                    </SRightImageWrapper>
                }
            />
        </ProjectMainLayout>
    );
};
